<template>
  <div>
   
  </div>
</template>

<script>

export default {
  data() {
    return {
      
    };
  },
  mounted(){
    
  },
  methods: {
     
  },
};
</script>

